import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ faqPage }) => {
      return children({
        faqPage,
      });
    }}
  />
);
const query = graphql`
  query FaqPage {
    faqPage: sanityFaqPage {
      pageTitle
      _rawQuestionsAndAnswers(resolveReferences: { maxDepth: 10 })
    }
  }
`;
