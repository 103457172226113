/**
 * A custom useEffect hook that expands and collapses the height of an element
 * @param {object} buttonRef
 * @param {object} wrapperRef
 * @param {object} innerRef
 */
import { useState, useEffect } from "react";
import useUpdateEffect from "./useUpdateEffect";

import { gsap } from "gsap";

export default function useToggleExpand(buttonRef, wrapperRef, innerRef) {
  const [expanded, setExpanded] = useState(false);
  const TOGGLE_SPEED = 0.1;

  const toggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    buttonRef.current.onclick = () => toggle();
  });

  useEffect(() => {
    wrapperRef.current.style.height = 0;
    wrapperRef.current.style.overflow = "hidden";
  }, []);

  useUpdateEffect(() => {
    if (expanded) {
      // expand
      gsap.to(wrapperRef.current, {
        duration: TOGGLE_SPEED,
        height: innerRef.current.clientHeight,
      });
    } else {
      // collapse
      gsap.to(wrapperRef.current, { duration: TOGGLE_SPEED, height: 0 });
    }
  }, [wrapperRef]);

  return expanded;
}
