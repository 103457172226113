import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import QFaqPage from "../queries/QFaqPage";
import FaqPage from "../components/faq-page";

const Faq = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <QFaqPage>
      {({ faqPage }) => (
        <Layout>
          <SEO title={faqPage.pageTitle} />
          <FaqPage {...faqPage} />
        </Layout>
      )}
    </QFaqPage>
  );
};

export default Faq;
