import React from "react";
import FaqList from "./faq-list";

const FaqPage = (props) => {
  const { pageTitle, _rawQuestionsAndAnswers, questionsAndAnswers } = props;
  const qAndAs = _rawQuestionsAndAnswers ? _rawQuestionsAndAnswers : questionsAndAnswers;

  return (
    <main>
      <FaqList title={pageTitle} questionsAndAnswers={qAndAs} />
    </main>
  );
};

export default FaqPage;
