import React from "react";
import { cn } from "../lib/helpers";
import { responsiveTitle1, pageTitle, bodyTextBlock } from "./typography.module.css";

import styles from "./faq-list.module.css";
import PortableText from "./portableText";
import useToggleExpand from "../lib/useToggleExpand";
import plusIcon from "../images/Capacity_PlusAccent-Green.svg";
import upArrow from "../images/Capacity_Arrow_Up.svg";

function FaqList(props) {
  const { title, questionsAndAnswers } = props;

  return (
    <div className={cn(styles.root)}>
      <div className={styles.content}>
        {title && <h2 className={cn(responsiveTitle1, pageTitle, styles.title)}>{title}</h2>}
        {questionsAndAnswers && (
          <ul className={styles.faqWrapper}>
            {questionsAndAnswers.map((item) => {
              const toggleButtonRef = React.createRef();
              const textWrapper = React.createRef();
              const textInner = React.createRef();
              const expanded = useToggleExpand(toggleButtonRef, textWrapper, textInner);

              return (
                <li className={styles.faqItem} key={item._key}>
                  <button className={styles.question} ref={toggleButtonRef}>
                    {item.question}
                    <span className={styles.expandIcon}>
                      {expanded ? (
                        <img className={styles.carrot} src={upArrow} alt="close" />
                      ) : (
                        <img className={styles.plus} src={plusIcon} alt="open" />
                      )}
                    </span>
                  </button>
                  <div className={styles.answer} ref={textWrapper}>
                    <div className={cn(bodyTextBlock, styles.answerInner)} ref={textInner}>
                      <PortableText blocks={item.answer} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default FaqList;
